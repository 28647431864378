<template>
  <div class="list-group text-center">
    <router-link
      class="list-group-item list-group-item-action rounded-0"
      v-for="route in routes"
      :key="route.name"
      :to="{name: route.name}"
    >{{route.displayName}}</router-link>
  </div>
</template>

<script>
export default {
  props: {
    routes: Array
  }
};
</script>

<style lang="scss" scoped>
a.list-group-item.active {
  background: var(--dark);
  border-color: var(--dark);
}
</style>
