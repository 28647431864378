<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <span class="navbar-brand">FITSEVENELEVEN</span>
    <small class="text-light d-none d-lg-block">Angemeldet als {{currentUser.email}}</small>
    <button class="navbar-toggler" type="button" @click="toggleNavbar()">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" ref="collapse">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item d-lg-none" v-for="route in routes" :key="route.name">
          <router-link :to="{name: route.name}" class="nav-link">{{route.displayName}}</router-link>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white cursor-pointer" @click="logout()">Abmelden</a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    currentUser: Object,
    routes: Array
  },
  methods: {
    toggleNavbar() {
      this.$refs.collapse.classList.toggle('show');
    },
    logout() {
      this.$emit('logout');
    }
  }
};
</script>


